import { IS_BROWSER } from 'aff-library-ui/constants/environment-constants';

import { type AppStoreState } from '#/src/shared/types';

if (IS_BROWSER) {
    // eslint-disable-next-line no-underscore-dangle
    window.__main = async (state: AppStoreState) => {
        const bootstrap = await import('./bootstrap');

        bootstrap.default(state);
    };
}
